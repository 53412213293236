import { ErrorNotification } from "@components/ErrorNotification"
import { Button } from "@components/ui/button"
import { Field } from "@components/ui/form/Field"
import { Link as UiLink } from "@components/ui/link"
import { parse } from "@config/theme"
import { parseError } from "@lib/parse-error"
import { Form, Formik } from "formik"
import { signIn, SignInResponse } from "next-auth/react"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { FC, useState } from "react"

type FormValues = {
  email: string
  password: string
}

export const LoginFormForm: FC = () => {
  const [error, setError] = useState<undefined | string>(undefined)
  const router = useRouter()
  return (
    <>
      {error && <ErrorNotification message={error} />}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={parse({ width: "100%" })}>
            <Field
              name="email"
              field="input"
              label="Email address"
              type="email"
              validation={{
                required: true,
                email: true,
              }}
            />
            <Field
              name="password"
              field="input"
              type="password"
              label="Password"
              mb="32"
              validation={{
                required: true,
              }}
              suffix={
                <Link href="/password-reset" passHref>
                  <UiLink size="small">Need help?</UiLink>
                </Link>
              }
            />
            <Button
              variant="primary"
              width="fill"
              type="submit"
              mb="20"
              status={isSubmitting ? "loading" : "idle"}
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )

  async function handleSubmit(values: FormValues) {
    const login = (await signIn("credentials", {
      ...values,
      redirect: false,
    })) as unknown as SignInResponse
    if (login.error) {
      const errorTitle = parseError(login.error)
      setError(errorTitle)
      if (process.env.NODE_ENV === "development") {
        console.error(login.error)
      }
    } else {
      setError(undefined)
      await router.prefetch("/app")
      await router.push("/app")
    }
  }
}

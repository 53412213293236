import { Button } from "@components/ui/button";
import { Heading } from "@components/ui/typograhpy/Heading";
import { Text } from "@components/ui/typograhpy/Text";
import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { BuiltInProviderType } from "next-auth/providers";
import { ClientSafeProvider, LiteralUnion, signIn } from "next-auth/react";
import Link from "next/link";
import React, { FC } from "react";
import { LoginFormForm as Form } from "./Form";
type LoginFormProps = {
  providers: Record<LiteralUnion<BuiltInProviderType, string>, ClientSafeProvider> | null;
};
export const LoginForm: FC<LoginFormProps> = ({
  providers
}) => {
  // @ts-ignore
  const google = providers && providers.google;
  // @ts-ignore
  const apple = providers && providers.apple;
  return <div className={container}>
      <Heading variant="h2" mb="32">
        Log in
      </Heading>
      <div className={content}>
        <Form />
        {(google || apple) && <>
            {google && <Button variant="secondary" data-testid="login-with-google" leftIcon="google" onClick={() => signIn((google.id as any))} mb="16" width="fill">
                Continue with Google
              </Button>}
            {apple && <Button variant="secondary" leftIcon="apple" mb="16" onClick={() => signIn((apple.id as any))} width="fill">
                Continue with Apple
              </Button>}
          </>}
        <Text variant="small" className={textGradiant}>
          {"New to Viesus? "}
          {<Link href="/signup/form" passHref>
              <a className={link}>Create an account</a>
            </Link>}
          !
        </Text>
      </div>
    </div>;
};
const link = "lctvln0";
const container = parse({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: {
    _: "center",
    medium: "flex-start"
  },
  width: "100%"
});
const content = parse({
  display: "flex",
  alignItems: "center",
  width: "100%"
}, "c1oyj5sr");
const textGradiant = "t4mpffr";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");
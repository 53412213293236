import { parse, parseAll } from "@config/theme";
import { css, cx } from "@linaria/core";
import React, { FC } from "react";
import { Theme, ThemeSystemProps } from "theme-system";
import IconDanger from "./ui/icons/IconDanger";
import { Text } from "./ui/typograhpy/Text";
type ErrorNotificationProps = Pick<ThemeSystemProps<Theme>, "mb"> & {
  message: string;
};
export const ErrorNotification: FC<ErrorNotificationProps> = ({
  message,
  mb = "20"
}) => {
  return <div className={cx(parent, parseAll({
    mb
  }))}>
      <IconDanger size={24} />
      <Text variant="regular" className={text}>
        {message}
      </Text>
    </div>;
};
const parent = parse({
  bg: "danger",
  width: "100%",
  px: "16",
  py: "12",
  display: "flex",
  alignItems: "center"
}, "p1bddmc1");
const text = parse({
  ml: "8"
});

require("./ErrorNotification.linaria.module.css!=!../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ErrorNotification.tsx");
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useDispatch } from "./store"
import { Action } from "./store/types"

export function useConfirmationAndResetNotification() {
  const router = useRouter()
  const { status } = useSession()
  const dispatch = useDispatch()
  const { activated, reset } = router.query

  useEffect(() => {
    if (activated) {
      if (status === "authenticated") {
        handleConfirmationAndLoginSuccess(dispatch)
      }
      if (status === "unauthenticated") {
        handleConfirmationSuccess(dispatch)
      }
    }
  }, [activated, dispatch, status])

  useEffect(() => {
    if (reset) {
      handleResetSuccess(dispatch)
    }
  }, [reset, dispatch])
}

function handleConfirmationAndLoginSuccess(dispatch: (action: Action) => void) {
  dispatch({
    type: "add-toast",
    payload: {
      variant: "success",
      title: "Your account has been activated!",
    },
  })
}

function handleConfirmationSuccess(dispatch: (action: Action) => void) {
  dispatch({
    type: "add-toast",
    payload: {
      variant: "success",
      title:
        "Your account has been activated! Log in with your username and password.",
    },
  })
}

function handleResetSuccess(dispatch: (action: Action) => void) {
  dispatch({
    type: "add-toast",
    payload: {
      variant: "success",
      title:
        "Your password has been reset! Log in with your username and new password.",
    },
  })
}
